import { createClient } from '@supabase/supabase-js';
import { env } from '../config/env';
import type { Database } from '../types/supabase';

const supabaseUrl = env.SUPABASE_URL;
const supabaseKey = env.SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase configuration. Please check your environment variables.');
}

export const supabase = createClient<Database>( 
  env.SUPABASE_URL,
  env.SUPABASE_ANON_KEY
);

// Helper to get the full storage URL for an image
export const getStorageImageUrl = (path: string) => {
  return `${supabaseUrl}/storage/v1/object/public/meal-images/${path}`;
};
import React from 'react';
import { HelpCircle, ChevronRight } from 'lucide-react';
import { Header } from './Header';

export const FAQ: React.FC = () => {
  const faqs = [
    {
      question: "How do I add a meal?",
      answer: "Click the 'Add Meal' button on the dashboard. You can either take a photo of your food for automatic analysis, or manually enter the meal details including name, carbs, calories, and sugar content."
    },
    {
      question: "How does the image analysis work?",
      answer: "When you upload or take a photo of your food, our AI system analyzes the image to identify the food items and estimate their nutritional content. While this provides a good estimate, you can always adjust the values manually for more accuracy."
    },
    {
      question: "How do I set my daily carb goal?",
      answer: "Click the settings icon in the header and enter your desired daily carbohydrate goal in grams. This goal will be used to track your progress and color-code your calendar entries."
    },
    {
      question: "What do the calendar colors mean?",
      answer: "In the calendar view, days are color-coded based on your carb intake: green indicates you stayed within your goal, red shows you exceeded it, and gray means no meals were logged that day."
    },
    {
      question: "Can I edit or delete a meal?",
      answer: "Yes! For each meal entry, you'll find edit and delete icons. The edit icon (pencil) allows you to modify any meal details, while the delete icon (trash) will remove the meal after confirmation."
    },
    {
      question: "How do I view my progress over time?",
      answer: "Use the calendar view to see your daily totals for each day of the month. This helps you track patterns and maintain consistency with your carb intake goals."
    },
    {
      question: "Is my data secure?",
      answer: "Yes, all your data is securely stored and can only be accessed with your account credentials. We use industry-standard encryption and security practices to protect your information."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center text-sm text-gray-500 mb-4">
          <a href="/" className="hover:text-blue-600">Home</a>
          <ChevronRight className="h-4 w-4 mx-2" />
          <span className="text-gray-900">FAQ</span>
        </div>
        
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center space-x-2 mb-8">
            <HelpCircle className="h-8 w-8 text-blue-500" />
            <h1 className="text-2xl font-bold text-gray-900">Frequently Asked Questions</h1>
          </div>

          <div className="space-y-8">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200 pb-6 last:border-0">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  <div className="flex items-start">
                    <span className="text-blue-500 mr-2">Q:</span>
                    {faq.question}
                  </div>
                </h3>
                <div className="pl-5">
                  <p className="text-gray-600 leading-relaxed">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};
import React from 'react';
import { CircleSlash } from 'lucide-react';

interface DailyProgressProps {
  totalCarbs: number;
  goal?: number;
}

export const DailyProgress: React.FC<DailyProgressProps> = ({ totalCarbs, goal = 300 }) => {
  const progress = Math.min((totalCarbs / goal) * 100, 100);
  
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-gray-900">Daily Progress</h2>
        <div className="flex items-center space-x-2">
          <CircleSlash className="h-5 w-5 text-blue-500" />
          <span className="text-sm text-gray-600">Goal: {goal}g</span>
        </div>
      </div>
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block text-blue-600">
              {totalCarbs}g consumed
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-blue-600">
              {Math.round(progress)}%
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-100">
          <div
            style={{ width: `${progress}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500"
          />
        </div>
      </div>
    </div>
  );
};
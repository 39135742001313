import React from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format, isSameDay } from 'date-fns';
import { Meal } from '../types/meal';
import { CalendarDay } from './CalendarDay';

interface CalendarProps {
  meals: Meal[];
  goal: number;
}

export const Calendar: React.FC<CalendarProps> = ({ meals, goal }) => {
  const today = new Date();
  const monthStart = startOfMonth(today);
  const monthEnd = endOfMonth(today);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const getDayMeals = (date: Date) => {
    return meals.filter(meal => isSameDay(new Date(meal.date), date));
  };

  const getDayTotal = (date: Date) => {
    return getDayMeals(date).reduce((sum, meal) => sum + meal.carbs, 0);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">
        {format(today, 'MMMM yyyy')}
      </h2>
      <div className="grid grid-cols-7 gap-2">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-center text-sm font-medium text-gray-500 py-2">
            {day}
          </div>
        ))}
        {days.map(day => (
          <CalendarDay
            key={day.toISOString()}
            date={day}
            meals={getDayMeals(day)}
            total={getDayTotal(day)}
            goal={goal}
          />
        ))}
      </div>
    </div>
  );
};
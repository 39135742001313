import React, { useState } from 'react';
import { format } from 'date-fns';
import { Trash2, Edit } from 'lucide-react';
import { Meal } from '../types/meal';
import { supabase, getStorageImageUrl } from '../lib/supabase';
import { ConfirmDialog } from './ConfirmDialog';
import { MealForm } from './MealForm';

interface MealListProps {
  meals: Meal[];
  onDelete: (id: string) => void;
  onUpdate: (id: string, data: Partial<Meal>) => void;
}

export const MealList: React.FC<MealListProps> = ({ meals, onDelete, onUpdate }) => {
  const [mealToDelete, setMealToDelete] = useState<string | null>(null);
  const [mealToEdit, setMealToEdit] = useState<Meal | null>(null);

  const handleDelete = (id: string) => {
    setMealToDelete(id);
  };

  const confirmDelete = () => {
    if (mealToDelete) {
      onDelete(mealToDelete);
      setMealToDelete(null);
    }
  };

  const handleEdit = (meal: Meal) => {
    setMealToEdit(meal);
  };

  const handleUpdate = (data: Partial<Meal>) => {
    if (mealToEdit) {
      onUpdate(mealToEdit.id, data);
      setMealToEdit(null);
    }
  };

  return (
    <div className="space-y-4">
      {meals.map((meal) => (
        <div 
          key={meal.id}
          className="bg-white rounded-lg shadow p-4 flex items-start justify-between"
        >
          <div className="flex space-x-4">
            {meal.imageUrl && (
              <div className="flex-shrink-0">
                <img
                  src={meal.imageUrl}
                  alt={`Photo of ${meal.name}`}
                  className="w-20 h-20 rounded-lg object-cover"
                  onError={(e) => {
                    const target = e.currentTarget;
                    if (!target.dataset.retried) {
                      target.dataset.retried = 'true';
                      target.src = meal.imageUrl?.replace('/meal-images/', '/meal-images/public/') || '';
                    } else {
                      target.src = 'https://via.placeholder.com/80?text=No+Image';
                    }
                  }}
                />
              </div>
            )}
            <div>
              <h3 className="font-medium text-gray-900">{meal.name}</h3>
              <p className="text-sm text-gray-500">
                {format(meal.date, 'MMM d, yyyy h:mm a')}
              </p>
              <div className="flex space-x-4 mt-1">
                <span className="text-sm text-gray-600">Carbs: {meal.carbs}g</span>
                <span className="text-sm text-gray-600">Calories: {meal.calories}</span>
                <span className="text-sm text-gray-600">Sugar: {meal.sugar}g</span>
              </div>
              {meal.notes && (
                <p className="text-sm text-gray-600 mt-1">{meal.notes}</p>
              )}
            </div>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => handleEdit(meal)}
              className="p-2 text-gray-400 hover:text-blue-500 rounded-full hover:bg-gray-100"
            >
              <Edit className="h-5 w-5" />
            </button>
            <button
              onClick={() => handleDelete(meal.id)}
              className="p-2 text-gray-400 hover:text-red-500 rounded-full hover:bg-gray-100"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>
      ))}
      
      {mealToDelete && (
        <ConfirmDialog
          title="Delete Meal"
          message="Are you sure you want to delete this meal? This action cannot be undone."
          onConfirm={confirmDelete}
          onCancel={() => setMealToDelete(null)}
        />
      )}
      
      {mealToEdit && (
        <MealForm
          initialData={mealToEdit}
          onSubmit={handleUpdate}
          onClose={() => setMealToEdit(null)}
        />
      )}
    </div>
  );
};
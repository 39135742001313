import React, { useState } from 'react';
import { X, AlertCircle, PenLine } from 'lucide-react';
import { ImageUpload } from './ImageUpload';
import { NutritionInfo } from '../types/nutrition';
import { resizeImage } from '../utils/image';
import { supabase, getStorageImageUrl } from '../lib/supabase';

interface MealFormProps {
  initialData?: Meal;
  onSubmit: (data: { name: string; carbs: number; calories: number; sugar: number; notes?: string; imageUrl?: string }) => void;
  onClose: () => void;
}

export const MealForm: React.FC<MealFormProps> = ({ initialData, onSubmit, onClose }) => {
  const [name, setName] = useState(initialData?.name || '');
  const [nutritionInfo, setNutritionInfo] = useState<NutritionInfo>({
    foodName: '',
    carbs: initialData?.carbs || 0,
    calories: initialData?.calories || 0,
    sugar: initialData?.sugar || 0
  });
  const [notes, setNotes] = useState(initialData?.notes || '');
  const [error, setError] = useState<string | null>(null);
  const [showManualEntry, setShowManualEntry] = useState(!!initialData);
  const [imageData, setImageData] = useState<string | null>(initialData?.imageUrl || null);
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name && !nutritionInfo.foodName) {
      setError('Please enter a meal name');
      return;
    }
    
    if (nutritionInfo.carbs === 0 && nutritionInfo.calories === 0 && nutritionInfo.sugar === 0) {
      setError('Please enter nutrition information');
      return;
    }

    let imageUrl: string | undefined;
    
    if (imageData) {
      try {
        setUploading(true);
        const resizedImage = await resizeImage(imageData);
        const fileName = `meal-${Date.now()}.jpg`;
        const filePath = `public/${fileName}`;
        
        const { data, error } = await supabase.storage
          .from('meal-images')
          .upload(filePath, base64ToBlob(resizedImage), {
            contentType: 'image/jpeg',
            upsert: false
          }); 
          
        if (error) throw error;
        imageUrl = getStorageImageUrl(filePath);
      } catch (error) {
        console.error('Error uploading image:', error);
        setError('Failed to upload image. Please try again.');
        return;
      } finally {
        setUploading(false);
      }
    }

    onSubmit({
      name: name || nutritionInfo.foodName,
      ...nutritionInfo,
      notes: notes || nutritionInfo.notes || undefined,
      imageUrl
    });
    onClose();
  };

  const handleImageAnalysis = (info: NutritionInfo) => {
    setNutritionInfo(info);
    if (info.foodName && !name) {
      setName(info.foodName);
    }
    if (info.notes) {
      setNotes(info.notes);
    }
    setImageData(info.imageData);
    setError(null);
    setShowManualEntry(true);
  };

  const handleError = (errorMessage: string) => {
    setError(errorMessage);
    setShowManualEntry(true);
    setNutritionInfo({ foodName: '', carbs: 0, calories: 0, sugar: 0 });
  };

  const handleManualEntry = () => {
    setShowManualEntry(true);
    setError(null);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{initialData ? 'Edit Meal' : 'Add Meal'}</h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {!showManualEntry && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Upload Food Image
                </label>
                <ImageUpload
                  onAnalysisComplete={handleImageAnalysis}
                  onError={handleError}
                />
              </div>
              
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={handleManualEntry}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <PenLine className="h-4 w-4 mr-2" />
                  Enter Details Manually
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-yellow-400 mr-2" />
                <p className="text-sm text-yellow-700">{error}</p>
              </div>
            </div>
          )}

          {(showManualEntry || error) && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">Meal Name</label>
                <input
                  type="text"
                  value={name || nutritionInfo.foodName}
                  onChange={(e) => setName(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Carbs (g)</label>
                  <input
                    type="number"
                    value={nutritionInfo.carbs}
                    onChange={(e) => setNutritionInfo(prev => ({ ...prev, carbs: parseFloat(e.target.value) || 0 }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Calories</label>
                  <input
                    type="number"
                    value={nutritionInfo.calories}
                    onChange={(e) => setNutritionInfo(prev => ({ ...prev, calories: parseFloat(e.target.value) || 0 }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Sugar (g)</label>
                  <input
                    type="number"
                    value={nutritionInfo.sugar}
                    onChange={(e) => setNutritionInfo(prev => ({ ...prev, sugar: parseFloat(e.target.value) || 0 }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Notes</label>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  rows={3}
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={uploading}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
                >
                  {uploading ? 'Uploading...' : initialData ? 'Save Changes' : 'Add Meal'}
                </button>
              </div>
            </>
          )}
        </form>
        
        {imageData && (
          <div className="mt-4">
            <img 
              src={imageData} 
              alt="Food preview" 
              className="w-full h-48 object-cover rounded-lg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

function base64ToBlob(base64: string): Blob {
  const parts = base64.split(';base64,');
  const contentType = parts[0].split(':')[1] || 'image/jpeg';
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};
import React, { useState } from 'react';
import { Upload, Camera, Loader2 } from 'lucide-react';
import { openAIService } from '../utils/openai';
import { NutritionInfo } from '../types/nutrition';
import { CameraCapture } from './CameraCapture';

interface ImageUploadProps {
  onAnalysisComplete: (nutritionInfo: NutritionInfo) => void;
  onError: (error: string) => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ onAnalysisComplete, onError }) => {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [showCamera, setShowCamera] = useState(false);

  const handleCameraCapture = (imageData: string) => {
    setPreviewUrl(imageData);
    analyzeImage(imageData, true);
  };

  const analyzeImage = async (imageData: string, fromCamera = false) => {
    try {
      setIsAnalyzing(true);

      const result = await openAIService.analyzeImage(imageData);
      
      if (result.success && result.data) {
        result.data.imageData = imageData;
        onAnalysisComplete(result.data);
      } else {
        onError(result.error || 'Failed to analyze image');
      }
    } catch (error) {
      onError('Failed to analyze image. Please try again.');
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result as string;
      analyzeImage(base64Image);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-center w-full">
        <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
          {isAnalyzing ? (
            <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <div className="flex space-x-4 mb-4">
                <label className="cursor-pointer px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center space-x-2">
                  <Upload className="h-5 w-5" />
                  <span>Upload Photo</span>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleFileUpload}
                    disabled={isAnalyzing}
                  />
                </label>
                <button
                  onClick={() => setShowCamera(true)}
                  className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center space-x-2"
                >
                  <Camera className="h-5 w-5" />
                  <span>Take Photo</span>
                </button>
              </div>
              <p className="text-sm text-gray-500">PNG, JPG or JPEG</p>
            </div>
          )}
        </div>
      </div>
      
      {previewUrl && (
        <div className="relative w-full h-48 rounded-lg overflow-hidden">
          <img
            src={previewUrl}
            alt="Food preview"
            className="w-full h-full object-cover"
          />
        </div>
      )}

      {showCamera && (
        <CameraCapture
          onCapture={handleCameraCapture}
          onClose={() => setShowCamera(false)}
        />
      )}
    </div>
  );
};
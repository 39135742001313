import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Landing } from './components/Landing';
import { Header } from './components/Header';
import { ThankYou } from './components/ThankYou';
import { MealForm } from './components/MealForm';
import { MealList } from './components/MealList';
import { DailyProgress } from './components/DailyProgress';
import { Calendar } from './components/Calendar';
import { FAQ } from './components/FAQ';
import { Auth } from './components/Auth';
import { useAuthStore } from './store/authStore';
import { useMealStore } from './store/mealStore'; 

function CalendarView() {
  const { user } = useAuthStore();
  const { meals } = useMealStore();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Calendar meals={meals} goal={user?.daily_carb_goal || 300} />
      </main>
    </div>
  );
}

function AppContent() {
  const [showMealForm, setShowMealForm] = useState(false);
  const { user, loading: authLoading, initialize } = useAuthStore();
  const { meals, loading: mealsLoading, fetchMeals, addMeal, updateMeal, removeMeal } = useMealStore();

  useEffect(() => {
    const initAuth = async () => {
      await initialize();
    };
    initAuth();
  }, [initialize]);

  useEffect(() => {
    if (user) {
      fetchMeals(user.id).catch(console.error);
    }
  }, [user, fetchMeals]);

  const totalCarbs = meals.reduce((sum, meal) => sum + meal.carbs, 0);
  const totalCalories = meals.reduce((sum, meal) => sum + meal.calories, 0);
  const totalSugar = meals.reduce((sum, meal) => sum + meal.sugar, 0);

  const handleAddMeal = (data: { name: string; carbs: number; calories: number; sugar: number; notes?: string }) => {
    if (user) {
      addMeal(user.id, {
        ...data,
        date: new Date(),
      });
      setShowMealForm(false);
    }
  };

  if (authLoading || mealsLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" aria-label="Loading..." />
      </div>
    );
  }

  if (!user) {
    return <Auth />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          <DailyProgress
            totalCarbs={totalCarbs}
            totalCalories={totalCalories}
            totalSugar={totalSugar}
            goal={user.daily_carb_goal}
          />
          
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-900">Today's Meals</h2>
            <button
              onClick={() => setShowMealForm(true)}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
            >
              Add Meal
            </button>
          </div>

          <MealList 
            meals={meals} 
            onDelete={removeMeal}
            onUpdate={updateMeal}
          />
        </div>

        {showMealForm && (
          <MealForm
            onSubmit={handleAddMeal}
            onClose={() => setShowMealForm(false)}
          />
        )}
      </main>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<AppContent />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/calendar" element={<CalendarView />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
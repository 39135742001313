import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, ArrowLeft } from 'lucide-react';

export const ThankYou: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <img
            src="https://abratron.com/images/CarbCounterLogo.png"
            alt="CarbCounter Logo"
            className="h-20 w-20"
          />
        </div>
        <div className="mt-6 text-center">
          <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
          <h2 className="mt-4 text-3xl font-extrabold text-gray-900">Thank you for signing up!</h2>
          <p className="mt-4 text-lg text-gray-600">
            Please check your email to confirm your account. Once confirmed, you can sign in and start tracking your meals.
          </p>
          <div className="mt-8">
            <Link
              to="/auth"
              className="inline-flex items-center text-blue-600 hover:text-blue-500"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Return to sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
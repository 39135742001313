import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { Profile } from '../types/database';

interface AuthState {
  user: Profile | null;
  loading: boolean;
  error: string | null;
  initialize: () => Promise<void>;
  updateProfile: (data: Partial<Profile>) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  loading: true,
  error: null,

  initialize: async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      set({ loading: true });
      
      if (session?.user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', session.user.id)
          .single();

        if (profile) {
          set({ user: profile, loading: false });
          return;
        }

        const newProfile = {
          id: session.user.id,
          email: session.user.email!,
          subscription_tier: 'free',
          daily_carb_goal: 300,
        };

        const { error: insertError } = await supabase
          .from('profiles')
          .insert([newProfile]);

        if (insertError) {
          throw insertError;
        }

        set({ user: newProfile as Profile, loading: false });
      } else {
        set({ user: null, loading: false });
      }

      // Set up auth state change listener
      supabase.auth.onAuthStateChange(async (event, session) => {
        if (session?.user) {
          const { data: profile } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', session.user.id)
            .single();

          if (!profile) {
            const newProfile = {
              id: session.user.id,
              email: session.user.email!,
              subscription_tier: 'free',
              daily_carb_goal: 300,
            };

            const { error: insertError } = await supabase
            .from('profiles')
              .insert([newProfile]);

            if (!insertError) {
              set({ user: newProfile as Profile, loading: false });
              return;
            }
          }
          
          set({ user: profile || null });
        } else {
          set({ user: null });
        }
        set({ loading: false });
      });
    } catch (error) {
      console.error('Error initializing auth:', error);
      set({ loading: false, error: 'Failed to initialize authentication' });
    }
  },

  signIn: async (email: string, password: string) => {
    try {
      set({ loading: true, error: null });
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      
      if (error) throw error;
      set({ loading: false });
    } catch (error) {
      console.error('Sign in error:', error);
      set({ loading: false, error: 'Invalid email or password' });
      throw error;
    }
  },

  signUp: async (email: string, password: string) => {
    try {
      set({ loading: true, error: null });
      const { error } = await supabase.auth.signUp({ 
        email, 
        password,
        options: {
          emailRedirectTo: 'https://carbcounterapp.com/auth'
        }
      });
      
      if (error) throw error;
      set({ loading: false });
    } catch (error) {
      console.error('Sign up error:', error);
      set({ loading: false, error: 'Failed to create account' });
      throw error;
    }
  },

  updateProfile: async (data: Partial<Profile>) => {
    const { user } = get();
    if (!user) return;

    try {
      const { error } = await supabase
        .from('profiles')
        .update(data)
        .eq('id', user.id);

      if (error) throw error;
      set({ user: { ...user, ...data } });
    } catch (error) {
      console.error('Error updating profile:', error);
      throw new Error('Failed to update profile');
    }
  },

  signOut: async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      set({ user: null, error: null });
    } catch (error) {
      console.error('Sign out error:', error);
      set({ error: 'Failed to sign out' });
      throw error;
    }
  }
}));
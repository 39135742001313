import React from 'react';
import { format } from 'date-fns';
import { Meal } from '../types/meal';

interface CalendarDayProps {
  date: Date;
  meals: Meal[];
  total: number;
  goal: number;
}

export const CalendarDay: React.FC<CalendarDayProps> = ({ date, meals, total, goal }) => {
  const getStatusColor = () => {
    if (meals.length === 0) return 'bg-gray-50';
    if (total > goal) return 'bg-red-50';
    return 'bg-green-50';
  };

  return (
    <div className={`p-2 rounded-lg ${getStatusColor()} min-h-[80px]`}>
      <div className="text-sm font-medium text-gray-700">
        {format(date, 'd')}
      </div>
      {meals.length > 0 && (
        <div className="mt-1">
          <div className={`text-sm font-medium ${total > goal ? 'text-red-600' : 'text-green-600'}`}>
            {total}g
          </div>
          <div className="text-xs text-gray-500">
            {meals.length} meal{meals.length !== 1 ? 's' : ''}
          </div>
        </div>
      )}
    </div>
  );
};
import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { Meal } from '../types/meal';

interface MealStore {
  meals: Meal[];
  loading: boolean;
  error: string | null;
  fetchMeals: (userId: string) => Promise<void>;
  addMeal: (userId: string, meal: Omit<Meal, 'id'>) => Promise<void>;
  updateMeal: (id: string, data: Partial<Meal>) => Promise<void>;
  removeMeal: (id: string) => Promise<void>;
}

export const useMealStore = create<MealStore>((set, get) => ({
  meals: [],
  loading: false,
  error: null,

  fetchMeals: async (userId: string) => {
    set({ loading: true, error: null });
    try {
      const { data, error } = await supabase
        .from('meals')
        .select('*')
        .eq('user_id', userId)
        .order('date', { ascending: false });

      if (error) throw error;

      const meals = data.map(meal => ({
        id: meal.id,
        name: meal.name,
        carbs: meal.carbs,
        calories: meal.calories,
        sugar: meal.sugar,
        date: new Date(meal.date),
        notes: meal.notes,
        imageUrl: meal.image_url
      }));

      set({ meals, loading: false });
    } catch (error) {
      console.error('Error fetching meals:', error);
      set({ 
        error: 'Failed to load meals. Please try again later.',
        loading: false 
      });
    }
  },

  addMeal: async (userId: string, meal: Omit<Meal, 'id'>) => {
    set({ error: null });
    try {
      const { data, error } = await supabase
        .from('meals')
        .insert([{
          user_id: userId,
          name: meal.name,
          carbs: meal.carbs,
          calories: meal.calories,
          sugar: meal.sugar,
          date: meal.date.toISOString(),
          notes: meal.notes || null,
          image_url: meal.imageUrl || null
        }])
        .select()
        .single();

      if (error) throw error;

      const newMeal: Meal = {
        id: data.id,
        name: data.name,
        carbs: data.carbs,
        calories: data.calories,
        sugar: data.sugar,
        date: new Date(data.date),
        notes: data.notes,
        imageUrl: data.image_url
      };

      set({ meals: [newMeal, ...get().meals] });
    } catch (error) {
      console.error('Error adding meal:', error);
      set({ error: 'Failed to add meal. Please try again.' });
      throw error;
    }
  },

  updateMeal: async (id: string, data: Partial<Meal>) => {
    set({ error: null });
    try {
      const { error } = await supabase
        .from('meals')
        .update({
          name: data.name,
          carbs: data.carbs,
          calories: data.calories,
          sugar: data.sugar,
          notes: data.notes || null,
          image_url: data.imageUrl || null
        })
        .eq('id', id);

      if (error) throw error;

      set(state => ({
        meals: state.meals.map(meal =>
          meal.id === id ? { ...meal, ...data } : meal
        )
      }));
    } catch (error) {
      console.error('Error updating meal:', error);
      set({ error: 'Failed to update meal. Please try again.' });
      throw error;
    }
  },

  removeMeal: async (id: string) => {
    set({ error: null });
    try {
      const { error } = await supabase
        .from('meals')
        .delete()
        .eq('id', id);

      if (error) throw error;
      set({ meals: get().meals.filter(meal => meal.id !== id) });
    } catch (error) {
      console.error('Error removing meal:', error);
      set({ error: 'Failed to remove meal. Please try again.' });
      throw error;
    }
  }
}));
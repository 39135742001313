import OpenAI from 'openai';
import { env } from '../config/env';
import { NutritionInfo, NutritionResponse } from '../types/nutrition';

if (!env.OPENAI_API_KEY) {
  throw new Error('OpenAI API key is not configured. Please add VITE_OPENAI_API_KEY to your .env file.');
}

class OpenAIService {
  private client: OpenAI;

  constructor() {
    this.client = new OpenAI({
      apiKey: env.OPENAI_API_KEY,
      dangerouslyAllowBrowser: true // Note: In production, use a backend proxy
    });
  }

  async analyzeImage(base64Image: string): Promise<NutritionResponse> {
    try {
      const response = await this.client.chat.completions.create({
        model: "gpt-4-vision-preview",
        messages: [
          {
            role: "system",
            content: "You are a precise nutritional analysis expert specializing in estimating nutritional content from food images. Provide detailed descriptions of what you see in the image along with accurate nutritional estimates."
          },
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Here is a photo of food I am eating. Please provide a detailed analysis including: 1) A thorough description of what you see in the image (portion sizes, ingredients, preparation method), and 2) Your best estimate of the nutritional content. Format your response exactly as: 'name:Brief Food Name,description:Detailed visual description including portion sizes and ingredients,carbs:X,calories:Y,sugar:Z' where X is total carbohydrates in grams, Y is total calories, and Z is sugar content in grams. Use numbers only for nutritional values, no units. If no food is detected, respond with 'NO_FOOD_DETECTED'."
              },
              {
                type: "image_url",
                image_url: {
                  url: base64Image
                }
              }
            ]
          }
        ],
        max_tokens: 300
      });

      const content = response.choices[0].message.content || '';
      
      if (content.trim() === 'NO_FOOD_DETECTED') {
        return {
          success: false,
          error: 'No food detected in the image. Please enter the meal information manually.'
        };
      }

      const nutritionValues = this.parseNutritionResponse(content);

      return {
        success: true,
        data: nutritionValues
      };
    } catch (error) {
      console.error('Error analyzing image:', error);
      return {
        success: false,
        error: 'Failed to analyze image. Please try again or enter the information manually.'
      };
    }
  }

  private parseNutritionResponse(content: string): NutritionInfo {
    const defaultValues = { foodName: '', description: '', carbs: 0, calories: 0, sugar: 0 };
    
    try {
      const pairs = content.split(',');
      const values: Partial<NutritionInfo> = {};

      pairs.forEach(pair => {
        const [key, value] = pair.split(':');
        const trimmedKey = key.trim() as keyof NutritionInfo;
        
        if (trimmedKey === 'name') {
          values.foodName = value.trim();
        } else if (trimmedKey === 'description') {
          values.description = value.trim();
        } else {
          const numValue = parseFloat(value);
          if (!isNaN(numValue)) {
            values[trimmedKey] = numValue;
          }
        }
      });

      // Add the description to notes if available
      if (values.description) {
        values.notes = values.description;
      }

      return { ...defaultValues, ...values };
    } catch (error) {
      console.error('Error parsing nutrition response:', error);
      return defaultValues;
    }
  }
}

export const openAIService = new OpenAIService();
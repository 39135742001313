import React from 'react';
import { Link } from 'react-router-dom';
import { Check, Zap, Infinity, Camera } from 'lucide-react';

interface PricingTier {
  name: string;
  price: string;
  description: string;
  features: string[];
  icon: React.ReactNode;
  buttonText: string;
  highlighted?: boolean;
}

export const PricingPanel: React.FC = () => {
  const tiers: PricingTier[] = [
    {
      name: "Free",
      price: "$0",
      description: "Perfect for getting started with carb tracking",
      icon: <Camera className="h-6 w-6 text-blue-500" />,
      features: [
        "Limited to 1 meal per day",
        "Basic nutritional tracking",
        "Photo to AI analysis",
        "Manual meal entry"
      ],
      buttonText: "Start Free"
    },
    {
      name: "Unlimited",
      price: "$19.99",
      description: "Unlimited tracking with advanced features",
      icon: <Zap className="h-6 w-6 text-blue-500" />,
      features: [
        "Unlimited meal analyses",
        "Detailed nutritional insights",
        "Full meal history",
        "Custom meal categories",
        "Advanced analytics",
        "Meal recommendations"
      ],
      buttonText: "Go Unlimited",
      highlighted: true
    }
  ];

  return (
    <div className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Pricing</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">
            Choose Your Plan
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
            Start tracking your nutrition journey with our flexible pricing options
          </p>
        </div>

        <div className="mt-20 grid gap-8 lg:grid-cols-2 lg:gap-x-8 max-w-4xl mx-auto">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className={`relative rounded-2xl shadow-xl overflow-hidden transition-transform hover:scale-105 ${
                tier.highlighted
                  ? 'border-2 border-blue-500 transform scale-105'
                  : 'border border-gray-200'
              }`}
            >
              {tier.highlighted && (
                <div className="absolute top-0 right-0 -mt-2 -mr-2 bg-blue-500 rounded-full p-2">
                  <Zap className="h-5 w-5 text-white" />
                </div>
              )}
              
              <div className="bg-white p-8">
                <div className="flex items-center justify-between">
                  <h3 className="text-2xl font-bold text-gray-900">{tier.name}</h3>
                  {tier.icon}
                </div>
                
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">{tier.price}</span>
                  <span className="text-base font-medium text-gray-500">/year</span>
                </p>

                <ul className="mt-8 space-y-4">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <div className="flex-shrink-0">
                        <Check className="h-5 w-5 text-green-500" />
                      </div>
                      <p className="ml-3 text-sm text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>

                <div className="mt-8">
                  <Link
                    to="/auth"
                    className={`block w-full py-3 px-6 text-center rounded-md shadow ${
                      tier.highlighted
                        ? 'bg-blue-600 hover:bg-blue-700 text-white'
                        : 'bg-gray-50 hover:bg-gray-100 text-blue-600'
                    } font-medium`}
                  >
                    {tier.buttonText}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
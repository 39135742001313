import React, { useState } from 'react';
import { Menu, X, Settings as SettingsIcon, Calendar as CalendarIcon, Home, LogOut, HelpCircle } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { Settings } from './Settings';
import { useAuthStore } from '../store/authStore';

export const Header: React.FC = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const { signOut } = useAuthStore();

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <button
              onClick={() => setShowMenu(true)}
              className="p-2 rounded-md hover:bg-gray-100"
            >
              <Menu className="h-6 w-6 text-gray-500"/>
            </button>
            <Link to="/" className="flex items-center ml-2">
              <img 
                src="https://abratron.com/images/CarbCounterLogo.png" 
                alt="CarbCounter Logo" 
                className="h-10 w-10"
              />
              <h1 className="ml-2 text-xl font-semibold text-gray-900">CarbCounter</h1>
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <Link
              to={location.pathname === '/calendar' ? '/' : '/calendar'}
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <CalendarIcon className="h-6 w-6 text-gray-500" />
            </Link>
            <button
              onClick={() => setShowSettings(true)}
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <SettingsIcon className="h-6 w-6 text-gray-500" />
            </button>
          </div>
        </div>
      </div>
      {showSettings && <Settings onClose={() => setShowSettings(false)} />}
      
      {/* Navigation Menu */}
      <div className={`fixed inset-0 z-50 transform transition-transform duration-300 ease-in-out ${showMenu ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setShowMenu(false)} />
        <div className="absolute inset-y-0 left-0 w-64 bg-white shadow-xl">
          <div className="p-6">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-xl font-semibold text-gray-900">Menu</h2>
              <button
                onClick={() => setShowMenu(false)}
                className="p-2 rounded-full hover:bg-gray-100"
              >
                <X className="h-5 w-5 text-gray-500" />
              </button>
            </div>
            
            <nav className="space-y-4">
              <Link
                to="/"
                className="flex items-center space-x-3 p-3 rounded-md hover:bg-gray-100"
                onClick={() => setShowMenu(false)}
              >
                <Home className="h-5 w-5 text-gray-500" />
                <span className="text-gray-700">Dashboard</span>
              </Link>
              
              <Link
                to="/calendar"
                className="flex items-center space-x-3 p-3 rounded-md hover:bg-gray-100"
                onClick={() => setShowMenu(false)}
              >
                <CalendarIcon className="h-5 w-5 text-gray-500" />
                <span className="text-gray-700">Calendar</span>
              </Link>
              
              <Link
                to="/faq"
                className="flex items-center space-x-3 p-3 rounded-md hover:bg-gray-100"
                onClick={() => setShowMenu(false)}
              >
                <HelpCircle className="h-5 w-5 text-gray-500" />
                <span className="text-gray-700">FAQ</span>
              </Link>
              
              <button
                onClick={() => {
                  setShowMenu(false);
                  setShowSettings(true);
                }}
                className="flex items-center space-x-3 p-3 rounded-md hover:bg-gray-100 w-full text-left"
              >
                <SettingsIcon className="h-5 w-5 text-gray-500" />
                <span className="text-gray-700">Settings</span>
              </button>
              
              <button
                onClick={() => {
                  setShowMenu(false);
                  handleSignOut();
                }}
                className="flex items-center space-x-3 p-3 rounded-md hover:bg-gray-100 w-full text-left"
              >
                <LogOut className="h-5 w-5 text-gray-500" />
                <span className="text-gray-700">Sign Out</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};